
import { Component, Vue, } from 'vue-property-decorator'

import AppVersion from '@/components/AppVersion/index.vue'

import InputHackmetrix from '@/components/InputHackmetrix/Index.vue'
import * as validations from '@/utils/Validations'

@Component({
  components: {
    AppVersion,
    InputHackmetrix,
  },
})
export default class LoginComponent extends Vue {
  private email = ''
  private showSuccess = false
  private messageInfo = ''
  private expirateMessage: string | (string | null)[] = ''
  private validator = validations

  mounted () {
    this.expirateMessage = this.$route.query.message
  }

  private goLogin () {
    this.$router.push('/')
  }

  private sendEmail () {
    this.$store.dispatch('AuthModule/ForgotPassword', this.email).then(() => {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Por favor revise su bandeja de entrada',
      })
      this.showSuccess = true
    })
  }

  private getEmail (value: string) {
    this.email = value
  }
}
